import React from "react"

import { AdminLayout } from "components/layout-custom"
import TeamRota from "components/rota"

import { Audience } from "types/audience"

const Page = () => (
  <AdminLayout title="Calendar" padded={false}>
    <TeamRota audience={Audience.admin} />
  </AdminLayout>
)

export default Page
